import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { MapContainer } from 'react-leaflet/MapContainer';
import { TileLayer } from 'react-leaflet/TileLayer';
import "leaflet/dist/leaflet.css";
import bbox from 'geojson-bbox';

export default function FieldMap({ data }) {
  const containerProps = {};
  try {
    const extent = bbox(data);
    containerProps['bounds'] = [[extent[1],extent[0]], [extent[3], extent[2]]]
  } catch (exceptionVar) {
    containerProps['center'] = [41.73583039, -95.70333052];
    containerProps['zoom'] = 10;
  }

  return (
    <MapContainer
      scrollWheelZoom
      style={{ height: '420px' }}
      {...containerProps}
    >
      <TileLayer
        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
        maxZoom= {20}
        subdomains={['mt1','mt2','mt3']}
      />
      <GeoJSON data={data} pathOptions={{ color: '#6988E7' }} />
    </MapContainer>
  );
}
