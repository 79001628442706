import React, { useState, useEffect } from 'react';
import { Marker, useMapEvents, useMap, Rectangle, MapContainer} from 'react-leaflet';
import { TileLayer } from 'react-leaflet/TileLayer';
import "leaflet/dist/leaflet.css";
import Control from 'react-leaflet-custom-control'
import { IoIosPin } from "react-icons/io";

function MapClickTracker({ setPosition }) {
  const map = useMapEvents({
    click: ({ latlng: { lat, lng } }) => {
      setPosition([lat, lng])
      // map.setView([lat, lng])
    }
  })
  return null
}

export default function WsFormMap({ gpsData }) {
  const defaultCoords = [41.73583039, -95.70333052];
  const gpsCoords = gpsData?.split(', ');
  const [position, zsetPosition] = useState(gpsCoords)
  const validGpsCoords = (position ? `${position[0]}, ${position[1]}` : '').split(", ").every((x) => /^-?\d*\.?\d*$/.test(x))

  const [isEditable, setIsEditable] = useState(false)
  const setPosition = (v) => {
    if (isEditable) {
      zsetPosition(v)
      setIsEditable(false)
    }
  } 

  return (
    <>
      <div className="control-group string optional storage_gps">
        <label className="string optional control-label" for="gps-react">GPS</label>
        <div className='controls'>
          <input
            className="string optional span12" 
            id="weather_station_gps"
            name='weather_station[gps]'
            value={position ? `${position[0]}, ${position[1]}` : ''}
            onChange={({ target: { value }}) => zsetPosition(value?.split(", ")) }
          />
        </div>
      </div>
      <MapContainer 
        center={gpsCoords || defaultCoords} 
        zoom={gpsCoords ? 15 : 10}
        scrollWheelZoom
        style={{ height: '400px' }}
        >
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
        />
          <Control prepend position='bottomleft'>
            <button onClick={(e) => {
              e.preventDefault() 
              setIsEditable(!isEditable)
            }
          }>
            <div style={{display: "flex", flexWrap: "wrap"}}>
              {isEditable ? <p style={{margin: '4px'}}>Cancel</p> : null }
              <span style={{margin: '5px'}}>
                <IoIosPin />
              </span>
            </div>
          </button>
          </Control>
        { position && validGpsCoords?  <Marker position={position} /> : null }
        <MapClickTracker setPosition={setPosition} />
      </MapContainer>
    </>
  );
}
