import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap'

const VehicleSelect = ({ form }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
 
  async function fetchData() {
    const url = `/equipment/by_client?secondary=vehicle`
    const response = await fetch(url);
    const data = await response.json();
    return (data)
  }

  useEffect(() => {
    setLoading(true)
    // call the function
    fetchData()
      .then((res) => {
        setData(res)
        setLoading(false)
      })
      // make sure to catch any error
      .catch((e) => console.log(e));
  }, [])

  const options = Object.keys(data).map((k) => ({ label: data[k], value: k}))
  return (
    <FormGroup>
      <Label for="vehicle_id">
        Vehicle:
      </Label>
      <select
        id="vehicle_id"
        style={{ width: '100%'}}
        name="vehicle_id"
        disabled={loading}
        {...form.register('vehicle_id')} 
      >
        <option disabled selected value>Select a Vehicle...</option>
        {options.map(o => <option value={o.value}>{o.label}</option>)}
      </select>
    </FormGroup>
  )
};

export default VehicleSelect;