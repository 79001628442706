import React, { useState, useEffect } from 'react';
import ActivityDetailCard from './ActivityDetailCard';
import ActualActivityFormCard from './ActualActivityFormCard'

export default function AddApplicationForm({ id }) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [selectOptionData, setSelectOptionData] = useState(null)

  async function fetchData() {
    const url = `/activities/${id}/new_application.json`
    const response = await fetch(url);
    const data = await response.json();
    return (data)
  }

  useEffect(() => {
    setLoading(true)
    // call the function
    fetchData()
      .then((res) => {
        setData(res.activity)
        setSelectOptionData(res.select_option_data)
        setLoading(false)
      })
      // make sure to catch any error
      .catch((e) => console.log(e));
  }, [])

  if (loading || !data) return <p>Loading...</p>

  return(
    <>
      <ActivityDetailCard data={data} />
      <ActualActivityFormCard data={data} selectOptionData={selectOptionData} />
    </>
  )
};