import React, { useState } from 'react';
import { ButtonGroup, Button } from 'reactstrap'
import { useForm } from 'react-hook-form';

import AddProductForm from '../AddProductForm';
import VehicleSelect from '../VehicleSelect';
import ImplementationSelect from '../ImplementationSelect';
import ActivityApplicationsMap from './Map';
import geojsonArea from '@mapbox/geojson-area';

const ActualActivityFormCard = ({ data, selectOptionData }) => {
  const [applicationType, setApplicationType] = useState();
  const field_geo = data.field?.geo_json ? JSON.parse(data.field?.geo_json) : null;
  const app_geo_json = data.app_geo_json ? JSON.parse(data.app_geo_json) : null;

  const form = useForm()

  const fieldSectionAcresList = field_geo.features.map(f => (geojsonArea.geometry(f.geometry) / 4046.85642).toFixed(2));
  const sum = fieldSectionAcresList.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue), 0).toFixed(2);

  const onSetApplicationType = (v) => {
    setApplicationType(v)
    if (v == 'whole_field') {
      form.setValue('acres_applied', sum)
      form.setValue('geo_json', app_geo_json)
    } else {
      form.setValue('acres_applied', null)
      form.setValue('geo_json', null)
    }
  }

  const submit = e => {
    e.preventDefault()
    const url = `/activities/${data.id}/create_application.json`
    const csrfToken = document.querySelector("[name='csrf-token']").content
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({ data: form.watch()}),
      headers: { "X-CSRF-Token": csrfToken, "content-type": 'application/json' },
    })
      .then(res => res.json())
      .then(json => {
        setApplicationType(null)
        form.reset()
        console.log('fug', json)
      })
  }
  
  return (
    <div style={{ border: 'solid', borderRadius: '5px', borderColor: '#999999', marginTop: '20px' }}>
      <h3 style={{ borderBottom: 'solid', borderColor: '#999999', paddingLeft: '4px'}}>Application Details</h3>
      <div style={{ padding: '20px'}}>
        <form onSubmit={submit}>
          <div style={{ marginBottom: "10px" }}> 
            <label for="Application Location" style={{ marginRight: 3}}>
              Application Location:
            </label>
            <ButtonGroup size="small" onChange={(e) => onSetApplicationType(e.target.value)}>
              <Button
                outline
                onClick={() => onSetApplicationType('whole_field')}
                active={applicationType === 'whole_field'}
              >
                Whole Field
              </Button>
      
              <Button
                outline
                onClick={() => onSetApplicationType('draw')}
                active={applicationType === 'draw'}
              >
                Draw on Map
              </Button>
            </ButtonGroup>

          {
            field_geo && app_geo_json && applicationType == 'draw'
              ? (
                <ActivityApplicationsMap
                  field={field_geo} 
                  activityId={data.id}
                  activity={app_geo_json}
                  setGeoJson={(v) => form.setValue('geo_json', v)}
                  setAcresApplied={(v) => form.setValue('acres_applied', v)}
                />
              )
              : null
          }
          </div>
          <div style={{ marginBottom: "10px" }}> 
            <label for="acres_applied" sm={2}>
              Acres Applied:
            </label>
              <input
                style={{ width: '98%' }}
                // type={null}
                placeholder="Acres Applied..."
                {...form.register('acres_applied')} 
              />
          </div>
          <VehicleSelect form={form} />    
          <ImplementationSelect form={form} />
          <div style={{ marginBottom: "10px", width: "97%" }}> 
            <label for="notes" sm={2}>
              Notes:
            </label>
              <textarea
                style={{ width: '100%'}}
                // type={null}
                placeholder="Notes..."
                {...form.register('notes')} 
              />
          </div>
          <div style={{ marginBottom: "10px" }}> 
            <label for="applied_prodcucts" sm={2}>
              Applied Products:
            </label>  
            <AddProductForm data={data} form={form} selectOptionData={selectOptionData} />
            <div style={{ border: 'solid', borderRadius: '5px', borderColor: '#999999'}}>
              {JSON.stringify(form.watch(), null, 2)}
            </div>
          </div>
          <input type="submit" name="Submit" />
        </form>
      </div>
    </div>
  );
};
export default ActualActivityFormCard;