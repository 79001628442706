import { Rectangle, useMap } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import Control from 'react-leaflet-custom-control'

const average = arr => arr ? arr.reduce( ( p, c ) => p + c, 0 ) / arr.length : null;

export default function ZoomTo({ legalAddressRecord, legalInputVal }) {
  const guardedLegalRecord = legalAddressRecord || {}
  const {
    min_boundry_lon,
    min_boundry_lat,
    max_boundry_lon,
    max_boundry_lat,
  } = guardedLegalRecord;

  // Outer bounds for legal address
  const bounds = min_boundry_lat ? [
    [min_boundry_lat, min_boundry_lon],
    [max_boundry_lat, max_boundry_lon]
  ] : null;

  // Outer bounds for legal address 2 if present
  const { legal1: xLegal1, legal2: xLegal2 } = legalInputVal;
  const legal1 = xLegal1?.toLowerCase() || ''
  const legal2 = xLegal2?.toLowerCase() || ''

  const leg2_mid_boundry_lon = max_boundry_lon && min_boundry_lon ? average([max_boundry_lon, min_boundry_lon]) : null
  const leg2_mid_boundry_lat = max_boundry_lon && min_boundry_lon ? average([max_boundry_lat, min_boundry_lat]) : null

  const leg2_max_boundry_lat = ['se', 'sw'].includes(legal2) ? leg2_mid_boundry_lat : max_boundry_lat
  const leg2_min_boundry_lat = ['ne', 'nw'].includes(legal2) ? leg2_mid_boundry_lat : min_boundry_lat
  const leg2_max_boundry_lon = ['nw', 'sw'].includes(legal2) ? leg2_mid_boundry_lon : max_boundry_lon
  const leg2_min_boundry_lon = ['ne', 'se'].includes(legal2) ? leg2_mid_boundry_lon : min_boundry_lon

  const legal2Bounds = bounds && ['ne', 'nw', 'se', 'sw'].includes(legal2) ? [
    [leg2_min_boundry_lat, leg2_min_boundry_lon],
    [leg2_max_boundry_lat, leg2_max_boundry_lon]
  ] : null

  const leg1_mid_boundry_lon = legal1 && legal2Bounds ? average([leg2_max_boundry_lon, leg2_min_boundry_lon]) : null
  const leg1_mid_boundry_lat = legal1 && legal2Bounds ? average([leg2_max_boundry_lat, leg2_min_boundry_lat]) : null

  const leg1_max_boundry_lat = ['se', 'sw'].includes(legal1) ? leg1_mid_boundry_lat : leg2_max_boundry_lat
  const leg1_min_boundry_lat = ['ne', 'nw'].includes(legal1) ? leg1_mid_boundry_lat : leg2_min_boundry_lat
  const leg1_max_boundry_lon = ['nw', 'sw'].includes(legal1) ? leg1_mid_boundry_lon : leg2_max_boundry_lon
  const leg1_min_boundry_lon = ['ne', 'se'].includes(legal1) ? leg1_mid_boundry_lon : leg2_min_boundry_lon

  const legal1Bounds = legal2Bounds && ['ne', 'nw', 'se', 'sw'].includes(legal1) ? [
    [leg1_min_boundry_lat, leg1_min_boundry_lon],
    [leg1_max_boundry_lat, leg1_max_boundry_lon]
  ] : null


  const map = useMap();
  return (
    <>
      {
        bounds
          ? <Rectangle bounds={bounds} pathOptions={{ color: 'red', fillOpacity: 0 }} />
          : null 
      }
      {
        legal2Bounds
          ? <Rectangle bounds={legal2Bounds} pathOptions={{ color: 'red', fillOpacity: 0 }} />
          : null 
      }
      {
        legal1Bounds
          ? <Rectangle bounds={legal1Bounds} pathOptions={{ color: 'red', fillOpacity: 0 }} />
          : null 
      }
        <Control prepend position='bottomright'>
        <button
          disabled={!bounds}
          onClick={(e) => {
            e.preventDefault() 
            map.fitBounds(bounds)
          }}
        >
          <div style={{display: "flex", flexWrap: "wrap"}}>
            <span style={{margin: '5px'}}>
              Zoom To Field Legal
            </span>
          </div>
        </button>
      </Control>
    </>
  )
}
