import React, { useEffect } from 'react';
import { Card, CardHeader, CardBody, ButtonGroup, Button } from 'reactstrap'

import { useFieldArray } from 'react-hook-form';

const AddProductForm = ({ data, selectOptionData, form }) => {
  const { fields, append, remove } = useFieldArray({
    control: form.control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "applied_products", // unique name for your Field Array
  });

  useEffect(() => {
    data?.products?.map((record, i) => {
      append(record)
    })
  }, [])

  return (
    fields.map((field, index) => (
      <div style={{ border: 'solid', borderRadius: '5px', borderColor: '#999999', marginTop: '20px' }}>
        <h3 style={{ borderBottom: 'solid', borderColor: '#999999', paddingLeft: '4px'}}>Product #{index + 1}</h3>
        <div style={{ padding: '20px'}}>
          <div style={{ marginBottom: "10px" }}> 
            <label for="amount_applied" sm={2}>
              Amount Applied:
            </label>
            <input
              key={field.id} // important to include key with field's id
              placeholder='Amount Applied...'
              style={{ width: '98%' }}
              {...form.register(`applied_products.${index}.amount_applied`)} 
            />
          </div>
          <div style={{ marginBottom: "10px" }}> 

            <label for="application_method_id">
              Planned Method of Application:
            </label>
            <select
              style={{ width: '100%'}}
              {...form.register(`applied_products.${index}.application_method_id`)} 
            >
              <option disabled selected value>Select a Planned Method of Application...</option>
              {selectOptionData.application_method_options.map(o => <option value={o.id}>{o.description}</option>)}
            </select>
          </div>
          <div style={{ marginBottom: "10px" }}> 
            <label for="Application Type">
            Application Type:
            </label>
            <select
              style={{ width: '100%'}}
              {...form.register(`applied_products.${index}.application_type`)} 
            >
              <option disabled selected value>Select an Application Type...</option>
              <option value="VRT">VRT</option>
              <option value="Blanket">Blanket</option>
            </select>
          </div>
          <div style={{ marginBottom: "10px" }}> 
            <label for="activity_type">
            Product Type:
            </label>
            <select
              style={{ width: '100%'}}
              {...form.register(`applied_products.${index}.activity_type`)} 
            >
              <option disabled selected value>Select a Product Type...</option>
              {selectOptionData.product_type_options.map(o => <option value={o[1]}>{o[0]}</option>)}
            </select>
          </div>
          <div style={{ marginBottom: "10px" }}> 
            <label for="storage_inventory_id">
            Storage:
            </label>
            <select
              style={{ width: '100%'}}
              {...form.register(`applied_products.${index}.storage_inventory_id`)} 
            >
              <option disabled selected value>Select a Storage...</option>
              {selectOptionData.storage_options.map(o => <option value={o.value}>{o.label}</option>)}
            </select>
          </div>
        </div>
      </div>
    ))
  );
}
export default AddProductForm;
