import React, { useState, useEffect } from 'react';
import { FeatureGroup, GeoJSON, MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import EditControl from '../../components/maps/EditControl';
import "leaflet/dist/leaflet.css";
import bbox from 'geojson-bbox';
import { uniqFeatures } from "../../utilities";
import geojsonArea from '@mapbox/geojson-area';

export default function ActivityApplicationsMap({ field, activity, setGeoJson, setAcresApplied }) {
  const extent = bbox(field); 
  const [features, xSetFeatures] = useState([])

  const setFeatures = (list) => {
    xSetFeatures(uniqFeatures(list))
  }

  const removeFeature = (indexToRemove) => {
    const result = [...features.slice(0, indexToRemove), ...features.slice(indexToRemove + 1)];
    setFeatures(result)
  }

  useEffect(() => {
    const val = JSON.stringify({ type: 'Feature Collection', features })
    if (val) {
      setGeoJson(val)
      const fieldSectionAcresList = features.map(f => (geojsonArea.geometry(f.geometry) / 4046.85642).toFixed(2));
      const sum = fieldSectionAcresList.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue), 0).toFixed(2);
      setAcresApplied(sum)
    }
  }, [JSON.stringify(features)])

  return(
    <>
      <MapContainer
        bounds={[[extent[1],extent[0]], [extent[3], extent[2]]]}
        scrollWheelZoom
        style={{ height: '420px', marginTop: 20 }}
      >
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
        />
        <LayersControl position="topright">
          <LayersControl.Overlay checked name="Field Outline">
            <GeoJSON data={field} pathOptions={{ color: '#6988E7', fillOpacity: 0 }}/>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Prescribed Area">
            <GeoJSON data={activity} pathOptions={{ color: 'yellow', fillOpacity: 0 }}/>
          </LayersControl.Overlay>
        </LayersControl>
        <FeatureGroup key={JSON.stringify(features)} pathOptions={{ color: 'orange' }}>
          <EditControl
            edit={{ edit: false, remove: false, }}
            position='bottomleft'
            features={features}
            skipFitBoundsOnChange
            setFeatures={setFeatures}
            draw={{ 
              circlemarker: false, 
              marker: false, 
              circle: false, 
              polyline: false,
              rectangle: true,
              polygon: true
            }}
          />
        </FeatureGroup>
      </MapContainer>
      {
        features.length
          ? (
            <div style={{border: "1px solid #f0f0f0"}}>
              <h4>Applied Sections</h4>
              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    <th scope="col">Section #</th>
                    <th scope="col">Acres</th>
                    <th scope="col">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    features.map((f, i) => (
                      <tr key={`featurerow${i}`}>
                      <th scope="row">{i + 1}</th>
                      <td>{(geojsonArea.geometry(f.geometry) / 4046.85642).toFixed(2)}</td>
                      <td>
                        <button 
                          style={{
                            marginLeft: '5px',
                            padding: '0px',
                            background: 'none',
                            border: 'none',
                            color: '#069',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.preventDefault()
                            removeFeature(i)
                          }}
                        >
                          remove
                        </button>
                      </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )
          : null
      }
    </>
  )
}
