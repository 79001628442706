import React from 'react';

const columns = [
  {
    title: 'Type',
    dataIndex: 'activity_type',
    id: 'activity_type',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    id: 'name',
  }
];

const ActivityProductsCard = ({ data: { products } }) => {
  return (
    <>
      {
        products.map((p, i) => (
          <div style={{ border: 'solid', borderRadius: '5px', borderColor: '#999999', padding: 20, paddingTop: 0, marginRight: 40 }}>
            <h2>Product #{i + 1}</h2>
            <div style={{ content: '', display: 'table', clear: 'both' }}>
              <div style={{ float: 'left', width: '50%' }}>
                <p><b style={{ marginRight: 4 }}>Product Type:</b>{p.activity_type}</p>
                <p><b style={{ marginRight: 4 }}>Product Name:</b>{p.name}</p>
              </div>
              <div style={{ float: 'left', width: '50%' }}>
                <p><b style={{ marginRight: 4 }}>Rate/Acre:</b>{p.rate}</p>
                <p><b style={{ marginRight: 4 }}>Amount:</b>{p.amount_applied}</p>
              </div>
            </div>
            <div style={{ width: '950%'}}>
              <p><b style={{ marginRight: 4 }}>Product Storage:</b>{p.storage_inventory_name || 'n/a'}</p>
              <p><b style={{ marginRight: 4 }}>Method of Application:</b>{p.application_method_description}</p>
            </div>
          </div>
        ))
      }
    </>
  )
}
export default ActivityProductsCard;