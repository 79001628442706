import React, { createRoot } from 'react-dom/client';
import OverviewMap from './OverviewMap';
import FieldMap from './FieldMap';
import MarkableMap from './MarkableMap';
import SiteFormMap from './SiteFormMap';
import StoragesFormMap from './StoragesFormMap';
import ActivitiesMap from './ActivitiesMap';
import ActivityApplicationsMap from './ActivityApplicationsMap';
import AddApplicationForm from './Activities/AddApplicationForm';
import NutrientApplicationReportSubForm from './NutrientApplicationReportSubForm';
import OperationFarmSubForm from './OperationFarmSubForm';
import WeatherStationFormMap from './WeatherStationFormMap';

function renderMap() {
  const overviewElement = document.getElementById('overview-map');
  if (overviewElement) {
    const data = JSON.parse(overviewElement.getAttribute('data-features'))
    const root = createRoot(overviewElement);
    if (data.features?.length) root.render(<OverviewMap data={data} />);
  }

  const siteFormElement = document.getElementById('site-form-map');
  if (siteFormElement) {
    const data = JSON.parse(siteFormElement.getAttribute('data-storage-geo-json'))
    const root = createRoot(siteFormElement);
    if (data.features?.length) root.render(<SiteFormMap data={data} />);
  }

  const wsFormElement = document.getElementById('ws-form-map');
  if (wsFormElement) {
    const dataStr = wsFormElement.getAttribute('data-gps')
    const root = createRoot(wsFormElement);
    root.render(<WeatherStationFormMap gpsData={dataStr} />)
  }

  const storagesFormElement = document.getElementById('storages-form-map');
  if (storagesFormElement) {
    const gpsData = JSON.parse(storagesFormElement.getAttribute('data-gps'))
    const root = createRoot(storagesFormElement);
    root.render(<StoragesFormMap gpsData={gpsData} />);
  }
  
  const fieldElement = document.getElementById('field-map');
  if (fieldElement) {
    let fieldOutline; 
    try {
      fieldOutline = JSON.parse(fieldElement.getAttribute('data-field'))
    } catch {}

    const root = createRoot(fieldElement);
    root.render(<FieldMap data={fieldOutline} />);
  }

  const markableElement = document.getElementById('markable-map');
  if (markableElement) {
    const field_json = JSON.parse(markableElement.getAttribute('data-field'))
    const planned = JSON.parse(markableElement.getAttribute('data-planned'))
    const root = createRoot(markableElement);
    root.render(<MarkableMap field={field_json} plannedActivity={planned} />);
  }

  const activitiesElement = document.getElementById('activities-map');
  if (activitiesElement) {
    let field_json; 
    let activity_geo_json;
    try {
      field_json = JSON.parse(activitiesElement.getAttribute('data-field'))
      activity_geo_json = JSON.parse(activitiesElement.getAttribute('data-activity'))
    } catch {}

    const activityId = JSON.parse(activitiesElement.getAttribute('data-activity-id'))
    const activitySheets = JSON.parse(activitiesElement.getAttribute('data-activity-sheets'))
    const readOnly = activitiesElement.getAttribute('data-read-only')
    const root = createRoot(activitiesElement);
    root.render(
      <ActivitiesMap
        field={field_json} 
        activityId={activityId}
        activity={activity_geo_json}
        activitySheets={activitySheets}
        readOnly={readOnly && readOnly === 'true'}
      />
    );
  }

  const activityApplicationsElement = document.getElementById('activity-applications-map');
  if (activityApplicationsElement) {
    const field_json = JSON.parse(activityApplicationsElement.getAttribute('data-field'))
    const activity_geo_json = JSON.parse(activityApplicationsElement.getAttribute('data-activity'))
    const activityId = JSON.parse(activityApplicationsElement.getAttribute('data-activity-id'))
    const ind = JSON.parse(activityApplicationsElement.getAttribute('data-application-index'))
    const root = createRoot(activityApplicationsElement);

    root.render(
      <ActivityApplicationsMap
        field={field_json} 
        activityId={activityId}
        activity={activity_geo_json}
        index={ind}
      />
    );
  }

  const addApplicationElement = document.getElementById('add-application-form');
  if (addApplicationElement) {
    const root = createRoot(addApplicationElement);
    const id = addApplicationElement.getAttribute('data-id')
    root.render(<AddApplicationForm id={id} />);
  }

  const nuReportSubForm = document.getElementById('nutrient-app-rep-sub-form');
  if (nuReportSubForm) {
    const root = createRoot(nuReportSubForm);
    const data = JSON.parse(nuReportSubForm.getAttribute('data-actual-activity-options'))
    root.render(<NutrientApplicationReportSubForm actualActivityOptions={data} />);
  }

  const siteFarmSubForm = document.getElementById('operation-farm-sub-form');
  if (siteFarmSubForm) {
    const root = createRoot(siteFarmSubForm);
    const sites = JSON.parse(siteFarmSubForm.getAttribute('data-site-options'))
    const farms = JSON.parse(siteFarmSubForm.getAttribute('data-farm-options'))
    const farmId = JSON.parse(siteFarmSubForm.getAttribute('data-farm-id'))
    const siteId = JSON.parse(siteFarmSubForm.getAttribute('data-site-id'))
    console.log('f', farmId)
    console.log('s', siteId)
    root.render(<OperationFarmSubForm farms={farms} sites={sites} farmId={farmId} siteId={siteId} />);
  }
}

window.renderMap = renderMap
renderMap()