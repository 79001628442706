import React, { useState } from 'react';
import { FeatureGroup, GeoJSON, MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import EditControl from './components/maps/EditControl';
import "leaflet/dist/leaflet.css";
import bbox from 'geojson-bbox';
import { uniqFeatures } from "./utilities";
import ActivitiesTable from './components/maps/ActivitiesTable';

export default function ActivitiesMap({ field, activity, activityId, activitySheets, readOnly }) {
  const extent = bbox(field); 
  const [drawEnabled, setDrawEnabled] = useState(false)
  const activityFeatures = (activity?.features || []).map((f, i) => ({...f, properties: { persistedToDb: true }}))
  const [features, xSetFeatures] = useState(activityFeatures)

  const setFeatures = (list) => {
    xSetFeatures(uniqFeatures(list))
  }

  const removeFeature = (indexToRemove) => {
    const result = [...features.slice(0, indexToRemove), ...features.slice(indexToRemove + 1)];
    xSetFeatures(result)
  }

  const setWholeField = () => {
    xSetFeatures(field.features)
  }

  const resetFeaturesFromBackend = (features) => {
    const persistedFeatures = features.map((f, i) => ({...f, properties: { persistedToDb: true }}))
    xSetFeatures(persistedFeatures)
  }

  return(
    <>
      <MapContainer
        bounds={[[extent[1],extent[0]], [extent[3], extent[2]]]}
        scrollWheelZoom
        style={{ height: '420px' }}
      >
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
        />
        <LayersControl position="topright">
          <LayersControl.Overlay checked name="Field Outline">
            <GeoJSON data={field} pathOptions={{ color: '#6988E7', fillOpacity: 0 }} />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Planned Activity">
            <FeatureGroup key={JSON.stringify(features)} pathOptions={{ color: 'yellow' }}>
              <EditControl
                edit={{ edit: false, remove: false, }}
                position='bottomleft'
                features={features}
                skipFitBoundsOnChange
                setFeatures={setFeatures}
                draw={{ 
                  circlemarker: false, 
                  marker: false, 
                  circle: false, 
                  polyline: false,
                  rectangle: drawEnabled,
                  polygon: drawEnabled
                }}
              />
            </FeatureGroup>
          </LayersControl.Overlay>
          {
            activitySheets?.map(as => as.applications.map(app => {
              let features;
              try {
                features = JSON.parse(app.geo_json).features;
              } catch {
                // do nothing
              }
              if (app.geo_json) return (
              <LayersControl.Overlay checked name={`AS ${as.id} -- APP ${app.id}`}>
                <GeoJSON data={features} pathOptions={{ color: 'red', fillOpacity: 0, weight: 1 }}/>
              </LayersControl.Overlay>
            )}
            ))
          }
        </LayersControl>

      </MapContainer>
      {
        !readOnly
          ? (
            <ActivitiesTable 
              features={features}
              activityFeatures={activityFeatures}
              setWholeField={setWholeField}
              setDrawEnabled={setDrawEnabled}
              drawEnabled={drawEnabled}
              removeFeature={removeFeature}
              activityId={activityId}
              resetFeaturesFromBackend={resetFeaturesFromBackend}
            />
          )
          : null
      }
    </>
  )
}
