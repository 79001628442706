import React, { useEffect, useState } from "react";
import { GeoJSON, Rectangle, MapContainer } from 'react-leaflet';
import { TileLayer } from 'react-leaflet/TileLayer';
import "leaflet/dist/leaflet.css";
import bbox from 'geojson-bbox';
import useDebounce from "./hooks/useDebounce";

export default function SiteFormMap({ data }) {
  const [loading, setLoading] = useState(false);
  const [legalAddressRecord, setLegalAddressRecord] = useState([]);
  const [legalDesc, setLegalDesc] = useState({
    legal1: document.getElementById("legal-q1")?.value,
    legal2: document.getElementById("legal-q2")?.value,
    range: document.getElementById("legal-range")?.value,
    range_direction: document.getElementById("legal-range-dir")?.value,
    section: document.getElementById("legal-section")?.value,
    state: document.getElementById("legal-state")?.value,
    township: document.getElementById("legal-township")?.value,
    township_direction: document.getElementById("legal-township-dir")?.value
  })

  const debouncedValue = useDebounce(legalDesc, 500);

  useEffect(() => {
    setLoading(true)
    async function fetchData() {
      const searchParams = new URLSearchParams({
        legal1: legalDesc.legal1, 
        legal2: legalDesc.legal2, 
        range: legalDesc.range, 
        range_direction: legalDesc.range_direction,
        section: legalDesc.section,
        state: legalDesc.state,
        township: legalDesc.township,
        township_direction: legalDesc.township_direction
      })
      const response = await fetch("/api/legal_addresses?" + searchParams);
      const data = await response.json();
      return (data)
    }

    // call the function
    fetchData()
      .then((res) => {
        setLegalAddressRecord(res.legal_address)
        setLoading(false)
      })
      // make sure to catch any error
      .catch(console.error);
  }, [JSON.stringify(debouncedValue)])

  const extent = bbox(data);
  const legalBounds = [
    [legalAddressRecord?.min_boundry_lat, legalAddressRecord?.min_boundry_lon],
    [legalAddressRecord?.max_boundry_lat, legalAddressRecord?.max_boundry_lon]
  ]

  function onEachFeature(feature, layer) {
    if (feature.properties && feature.properties.name && feature.properties.operation) {
        layer.bindTooltip(feature.properties.name + ' - ' + feature.properties.operation);
    } else if (feature.properties && feature.properties.name) {
        layer.bindTooltip(feature.properties.name);
    }
  }

  function onChangeInput({ target: { id, value } }) {
    const mapping = {
      legal1: "legal-q1",
      legal2: "legal-q2",
      section: "legal-section", 
      township: "legal-township",
      township_direction: "legal-township-dir",
      range: "legal-range",
      range_direction: "legal-range-dir",
      state: "legal-state",
    }
    document.getElementById(mapping[id]).value = value;
    setLegalDesc({...legalDesc, [id]: value })
  }

  if (loading) return <p style={{ marginTop: 10 }}>Loading...</p>

  return (
    <>
      <div style={{display: "flex", flexWrap: "wrap"}} className="control-group string optional site_Legal Description legal">
        <label className="string optional control-label" for="site_Legal Description">
          Legal Description
        </label>
        <div className="controls">
          <div style={{display: "flex", flexWrap: "wrap"}}>
            <input style={{width: "2em"}} id="legal1" onChange={onChangeInput} value={legalDesc.legal1} type="text" />
            <p style={{ margin: 4, color: 'rgb(102, 102, 102)' }}>1/4 of the</p>
            <input style={{width: "2em"}} id="legal2" onChange={onChangeInput} value={legalDesc.legal2} type="text" />
            <p style={{ margin: 4, color: 'rgb(102, 102, 102)' }}>1/4 of Sec</p>
            <input style={{width: "3em"}} id="section" onChange={onChangeInput} value={legalDesc.section} type="text" />
          </div>
          <div style={{display: "flex", flexWrap: "wrap", marginTop: 8 }}>
            <p style={{ margin: 4, color: 'rgb(102, 102, 102)' }}>T</p>
            <input style={{width: "3em", marginRight: 4 }} id="township" onChange={onChangeInput} value={legalDesc.township} type="text" />
            <input style={{width: "2em"}} id="township_direction" onChange={onChangeInput} value={legalDesc.township_direction} type="text" />
            <p style={{ margin: 4, color: 'rgb(102, 102, 102)' }}>R</p>
            <input style={{width: "3em", marginRight: 4 }} id="range" onChange={onChangeInput} value={legalDesc.range} type="text" />
            <input style={{width: "2em"}} id="range_direction" onChange={onChangeInput} value={legalDesc.range_direction} type="text" />
            <p style={{ margin: 4, color: 'rgb(102, 102, 102)' }}>State</p>
            <input style={{width: "2em"}} id="state" onChange={onChangeInput} value={legalDesc.state} type="text" />
          </div>
        </div>
      </div>
      <MapContainer
        bounds={[[extent[1],extent[0]], [extent[3], extent[2]]]}
        scrollWheelZoom
        style={{ height: '600px' }}
      >
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
        />
          <GeoJSON data={data} onEachFeature={onEachFeature} />
          { legalAddressRecord?.min_boundry_lat ? <Rectangle bounds={legalBounds} pathOptions={{ color: 'red', fillOpacity: 0 }} /> : null }
      </MapContainer>
    </>
  );
}
