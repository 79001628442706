import React from 'react';
import ActivityPrescribedProductsCard from './ActivityPrescribedProductsCard';

const ActivityDetailCard = ({ data }) => {
  return (
    <div style={{ border: 'solid', borderRadius: '5px', borderColor: '#999999'}}>
      <h3 style={{ borderBottom: 'solid', borderColor: '#999999', paddingLeft: '4px'}}>Activity Details</h3>
      <div style={{ padding: '20px', content: '', display: 'table', clear: 'both', width: '100%' }}>
        <div style={{ float: 'left', width: '50%' }}>
          <div>
            <b style={{ marginRight: 4 }}>Job Code:</b>{data.job_code}
          </div>
          <div>
            <b style={{ marginRight: 4 }}>Farm:</b>{data.farm}
          </div>
          <div>
            <b style={{ marginRight: 4 }}>Crop Year:</b>{data.year}
          </div>
          <div>
            <b style={{ marginRight: 4 }}>Acres Planned:</b>{data.acres_planned}
          </div>
        </div>
        <div style={{ float: 'left', width: '50%' }}>
          <div>
            <b style={{ marginRight: 4 }}>Client:</b>{data.client}
          </div>
          <div>
            <b style={{ marginRight: 4 }}>Main Activity:</b>{data.main_activity}
          </div>
          <div>
            <b style={{ marginRight: 4 }}>Field:</b>{data.field?.name}
          </div>
          <div>
            <b style={{ marginRight: 4 }}>Application Type:</b>{data.application_type}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <b style={{ marginRight: 4 }}>Prescribed Products:</b>
        </div>
        <ActivityPrescribedProductsCard data={data} />
      </div>
    </div>
)};

export default ActivityDetailCard;