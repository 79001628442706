import 'leaflet-draw';
import isEqual from 'fast-deep-equal';
import React, { useRef, useEffect } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import { useMap } from 'react-leaflet';
import bbox from 'geojson-bbox';
import './mapStyle.css'
import leaflet, { Control } from 'leaflet';

// Icon workaround
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

const eventHandlers = {
  onEdited: 'draw:edited',
  onDrawStart: 'draw:drawstart',
  onDrawStop: 'draw:drawstop',
  onDrawVertex: 'draw:drawvertex',
  onEditStart: 'draw:editstart',
  onEditMove: 'draw:editmove',
  onEditResize: 'draw:editresize',
  onEditVertex: 'draw:editvertex',
  onEditStop: 'draw:editstop',
  onDeleted: 'draw:deleted',
  onDeleteStart: 'draw:deletestart',
  onDeleteStop: 'draw:deletestop',
};

function EditControl(props) {
  const map = useMap();

  const context = useLeafletContext();
  const drawRef = useRef();
  const propsRef = useRef(props);

  const onDrawCreate = ({ layer }) => {
    const { onCreated } = props;
    props.setFeatures([...props.features, layer.toGeoJSON()])
  };

  useEffect(() => {
    const { map } = context;
    const { onMounted } = props;

    for (const key in eventHandlers) {
      map.on(eventHandlers[key], (evt) => {
        let handlers = Object.keys(eventHandlers).filter(
          (handler) => eventHandlers[handler] === evt.type
        );
        if (handlers.length === 1) {
          let handler = handlers[0];
          props[handler] && props[handler](evt);
        }
      });
    }
    map.on(leaflet.Draw.Event.CREATED, onDrawCreate);
    drawRef.current = createDrawElement(props, context);
    map.addControl(drawRef.current);
    onMounted && onMounted(drawRef.current);

    return () => {
      map.off(leaflet.Draw.Event.CREATED, onDrawCreate);

      for (const key in eventHandlers) {
        if (props[key]) {
          map.off(eventHandlers[key], props[key]);
        }
      }

      drawRef.current.remove(map);
    };
  }, []);

  useEffect(() => {
    if (
      isEqual(props.draw, propsRef.current.draw) &&
      isEqual(props.edit, propsRef.current.edit) &&
      props.position === propsRef.current.position
    ) {
      return;
    }
    const { map } = context;

    drawRef.current.remove(map);
    drawRef.current = createDrawElement(props, context);
    drawRef.current.addTo(map);

    const { onMounted } = props;
    onMounted && onMounted(drawRef.current);

    return () => {
      drawRef.current.remove(map);
    };
  }, [props.draw, props.edit, props.position]);

  useEffect(() => {
    const container = context.layerContainer || context.map;
    container.eachLayer(function (layer) {
      container.removeLayer(layer);
    });

    const layer = leaflet.geoJSON({ type: 'FeatureCollection', features: props.features })
    container.addLayer(layer);

    if (props.features.length) {
      const extent = bbox({ type: "FeatureCollection", features: props.features });
      if (!props.skipFitBoundsOnChange) {
        map.fitBounds([[extent[1], extent[0]], [extent[3], extent[2]]])
      }
    }
    
  }, [JSON.stringify(props.features)])

  // We need this for the ability to draw rectangles to not error out
  window.type = true;

  return null;
}

function createDrawElement(props, context) {
  const { layerContainer } = context;
  const { draw, edit, position } = props;
  const options = {
    edit: {
      ...edit,
      featureGroup: layerContainer,
    },
  };

  if (draw) {
    options.draw = { ...draw };
  }

  if (position) {
    options.position = position;
  }

  return new Control.Draw(options);
}

export default EditControl;